import {
  Build as BuildIcon,
  Construction,
  Edit as EditIcon,
  History as HistoryIcon,
  LockClock,
} from "@mui/icons-material";
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { formatDistance } from "date-fns";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";

import {
  calculateUrgencyLevel,
  formatServiceDate,
  intlFormatDate,
  intlFormatDateTimeWithWeekday,
  toDate,
} from "helpers";
import { localeMap } from "lang";
import { useAppDispatch, useAppSelector } from "store";
import { selectPageSelectedJob } from "store/root.store";
import { setEquipmentSpecificationsOpen, setMachinePropertiesOpen } from "store/slices/jobs.store";

import { DetailAccordion } from "components/DetailAccordion";
import { StyledChips } from "components/StyledChips";
import StyledTextFieldReadOnly from "components/StyledTextFieldReadOnly";
import Contacts from "components/job/details/Contacts";
import { EquipmentSpecificationsDialog } from "components/job/equipment/EquipmentSpecificationsDialog";
import { selectEngineerSettings } from "store/slices/user.store";

const PREFIX = "JobDetailTab";

const classes = {
  symptomDescription: `${PREFIX}-symptomDescription`,
  bold: `${PREFIX}-bold`,
  rightIconAdjust: `${PREFIX}-right-icon-adjust`,
  leftIconAdjust: `${PREFIX}-left-icon-adjust`,
  historyIconAdjust: `${PREFIX}-eq-history-icon-adjust`,
  iconAutoMargin: `${PREFIX}-icon-auto-margin`,
  iconInfo: `${PREFIX}-icon-info`,
  noPadding: `${PREFIX}-no-padding`,
  leftIndent: `${PREFIX}-left-indent`,
  disabled: `${PREFIX}-disabled`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`& .${classes.bold}`]: {
    fontWeight: 700,
  },

  [`& .${classes.rightIconAdjust}`]: {
    position: "relative",
    top: "0.2em",
    left: "0.2em",
  },
  [`& .${classes.leftIconAdjust}`]: {
    position: "relative",
    top: "0.2em",
  },
  [`& .${classes.historyIconAdjust}`]: {
    color: theme.palette.info.main,
    position: "relative",
    bottom: "0.1rem",
    marginRight: "0.2rem",
  },
  [`& .${classes.iconAutoMargin}`]: {
    margin: "auto",
  },
  [`& .${classes.iconInfo}`]: {
    color: theme.palette.info.main,
  },
  [`& .${classes.noPadding}`]: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  [`& .${classes.leftIndent}`]: {
    marginLeft: "38px",
  },
  [`& .${classes.disabled}`]: {
    color: theme.palette.secondary.main,
  },
}));

interface JobDetailTabProps {
  showUrgency?: boolean;
  readonly?: boolean;
}

export const JobDetailTab: FC<JobDetailTabProps> = (props: JobDetailTabProps) => {
  const { showUrgency, readonly } = props;
  const intl = useIntl();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const job = useAppSelector(selectPageSelectedJob);
  const engineerSettings = useAppSelector(selectEngineerSettings);

  const backTo = pathname.split("/").filter((x) => x)[0];
  const contacts = job.customer?.contacts || [];
  const plannedDate = job.plannedDate;
  const responseDate = job.responseDate;
  const machineInstallDate = job.equipment?.installDate;
  const nextServiceDate = formatServiceDate(job.equipment?.details?.nextServiceDate);
  const lastServiceDate = formatServiceDate(job.equipment?.details?.lastServiceDate);
  const urgencyLevel = calculateUrgencyLevel(job.plannedDate?.startTime || job.responseDate);

  const urgencyCopy = (plannedDate: Date | string): string => {
    const now = new Date();
    const date = toDate(plannedDate);
    const relativeDate = formatDistance(date, new Date(), {
      locale: localeMap(intl.locale).locale,
    });

    let visitDatestring: string;
    if (date < now) {
      visitDatestring = intl.formatMessage({ id: "visit.plannedDate.past" }, { relativeDate });
    } else {
      visitDatestring = intl.formatMessage({ id: "visit.plannedDate.future" }, { relativeDate });
    }
    return visitDatestring;
  };

  const openServiceHistory = (id: string, type: string | null | undefined) => {
    if (!readonly) {
      navigate("/history/" + type + "/" + id, {
        state: {
          navTitle: type === "site" ? job.customer?.name : job.equipment?.name,
          backTo: backTo,
        },
      });
    }
  };

  const urgencyAlert =
    urgencyLevel !== undefined && job.plannedDate?.startTime && job.responseDate ? (
      <Alert severity={urgencyLevel}>{urgencyCopy(job.plannedDate.startTime)}</Alert>
    ) : null;

  const plannedString = plannedDate?.startTime ? (
    <>
      <Typography
        variant="h6"
        sx={{
          fontSize: "1.2rem",
        }}
        color="black"
      >
        {intl.formatMessage({ id: "visit.planned" })}
      </Typography>
      <Typography sx={{ marginLeft: "38px" }}>
        {intlFormatDateTimeWithWeekday(intl, plannedDate.startTime)}
      </Typography>
      {plannedDate.stopTime && (
        <>
          <Typography sx={{ marginLeft: "38px" }}>
            {intl.formatMessage({ id: "times.to" })}
          </Typography>
          <Typography sx={{ marginLeft: "38px" }}>
            {intlFormatDateTimeWithWeekday(intl, plannedDate.stopTime)}
          </Typography>
        </>
      )}
    </>
  ) : (
    intl.formatMessage({ id: "general.unplanned" })
  );

  const responseString = responseDate ? (
    <>
      <LockClock className={classes.leftIconAdjust} sx={{ mr: 1.75 }} />
      {intlFormatDateTimeWithWeekday(intl, responseDate)}
    </>
  ) : (
    intl.formatMessage({ id: "general.unplanned" })
  );

  const historyIcon = (
    <HistoryIcon className={`${classes.historyIconAdjust} ${readonly ? classes.disabled : ""}`} />
  );

  const formatCustomProperty = function (type: string | undefined, value: any) {
    switch (type) {
      case "Boolean":
        return value
          ? intl.formatMessage({ id: "general.yes" })
          : intl.formatMessage({ id: "general.no" });
      case "DateTime":
        return intlFormatDateTimeWithWeekday(intl, value);
      case "Date":
        return intlFormatDate(intl, value);
      case "TimeOfDay":
      case "Decimal":
      case "DropDown":
      case "Int32":
      case "String":
      default:
        return value;
    }
  };

  return (
    <StyledCard elevation={1} data-testid="JobDetailTab">
      {showUrgency && urgencyAlert}
      <CardHeader
        className="e2e-jobDetailHeader"
        sx={{
          fontSize: "1.2rem",
        }}
        title={
          <>
            {plannedDate ? plannedString : responseString}
            {plannedDate && responseDate && (
              <Typography variant="body2" color="secondary">
                {responseString}
              </Typography>
            )}
            <ListItem>
            {job.externalOrderNumber && (
              <Typography>
                External Order Number: {job.externalOrderNumber}
              </Typography>
            )}
            </ListItem>
            <ListItem component="div" disableGutters className={classes.noPadding}>
              {job.customer?.id && (
                <ListItemIcon
                  onClick={() => openServiceHistory(job.customer!.id, "site")}
                  className="min-width-38 e2e-site-history-icon"
                  sx={{ alignSelf: "flex-start", mt: 1 }}
                  data-testid="JobDetailTab-OpenCustomerHistory"
                >
                  {historyIcon}
                </ListItemIcon>
              )}
              <ListItemText>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "1.2rem",
                  }}
                >
                  {job.customer?.name}
                </Typography>
                {(job.customer?.isVIP ||
                  !!job.customer?.serviceRegion ||
                  !!job.customer?.salesRegion ||
                  !!job.customer?.customerNo) && (
                  <DetailAccordion
                    content={
                      <>
                        {job.customer?.id && (
                          <Typography>
                            {`${intl.formatMessage({
                              id: "job.customer.customerNo",
                            })}: ${job.customer?.customerNo}`}
                          </Typography>
                        )}
                        {job.customer?.isVIP && <Typography>VIP</Typography>}
                        {!!job.customer?.serviceRegion && (
                          <Typography>
                            {`${intl.formatMessage({
                              id: "job.customer.serviceRegion",
                            })}: ${job.customer.serviceRegion}`}
                          </Typography>
                        )}
                        {!!job.customer?.salesRegion && (
                          <Typography>
                            {`${intl.formatMessage({ id: "job.customer.salesRegion" })}: ${
                              job.customer?.salesRegion
                            }`}
                          </Typography>
                        )}
                      </>
                    }
                  />
                )}
              </ListItemText>
            </ListItem>
          </>
        }
        subheader={
          <>
            <ListItem component="div" disableGutters className={classes.noPadding}>
              {job.equipment?.id && (
                <ListItemIcon
                  onClick={() => openServiceHistory(job.equipment!.id!, "equipment")}
                  className={`min-width-38 e2e-machine-history-icon ${classes.iconAutoMargin}`}
                  sx={{ alignSelf: "flex-start", mt: 1 }}
                  data-testid="JobDetailTab-OpenEquipmentHistory"
                >
                  {historyIcon}
                </ListItemIcon>
              )}
              <ListItemText className={classes.bold} primary={job.equipment?.name} />
            </ListItem>

            <div className={classes.leftIndent}>
              <Typography>{job.equipment?.serialNumber}</Typography>
              {job.equipment?.assetNumber && <Typography>{job.equipment.assetNumber}</Typography>}
              {job.equipment?.location &&
                (job.equipment.location.startsWith("///") ? (
                  <Link
                    href={`https://what3words.com/${job.equipment.location.replace("///", "")}`}
                    underline="none"
                    color="blue"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {job.equipment.location}
                  </Link>
                ) : (
                  <Typography>{job.equipment.location}</Typography>
                ))}
              {machineInstallDate && (
                <Typography>
                  {`${intl.formatMessage({
                    id: "general.installed",
                  })} ${intlFormatDate(intl, machineInstallDate)}`}
                </Typography>
              )}
              {!!job.equipment?.details && (
                <DetailAccordion
                  data-testid="JobDetailTab-DetailAccordion"
                  content={
                    <>
                      {!!job.equipment.details.information && (
                        <Typography>
                          {`${intl.formatMessage({
                            id: "job.equipment.details.information",
                          })}: ${job.equipment.details.information}`}
                        </Typography>
                      )}
                      {!!lastServiceDate && (
                        <Typography>
                          {`${intl.formatMessage({
                            id: "job.equipment.details.lastServiceDate",
                          })}: ${intlFormatDate(intl, lastServiceDate)}`}
                        </Typography>
                      )}
                      {!!nextServiceDate && (
                        <Typography>
                          {`${intl.formatMessage({
                            id: "job.equipment.details.nextServiceDate",
                          })}: ${intlFormatDate(intl, nextServiceDate)}`}
                        </Typography>
                      )}
                      {!!job.equipment.details.buildStandard && (
                        <Typography>
                          {`${intl.formatMessage({
                            id: "job.equipment.details.buildStandard",
                          })}: ${job.equipment.details.buildStandard}`}
                        </Typography>
                      )}
                      {!!job.equipment.details.systemProtocol && (
                        <Typography>
                          {`${intl.formatMessage({
                            id: "job.equipment.details.systemProtocol",
                          })}: ${job.equipment.details.systemProtocol}`}
                        </Typography>
                      )}
                      {!!job.equipment.details.signalType && (
                        <Typography>
                          {`${intl.formatMessage({
                            id: "job.equipment.details.signalType",
                          })}: ${job.equipment.details.signalType}`}
                        </Typography>
                      )}
                      {!!job.equipment.details.securityGrade && (
                        <Typography>
                          {`${intl.formatMessage({
                            id: "job.equipment.details.securityGrade",
                          })}: ${job.equipment.details.securityGrade}`}
                        </Typography>
                      )}
                      {!!job.equipment.details.gradeOfNotification && (
                        <Typography>
                          {`${intl.formatMessage({
                            id: "job.equipment.details.gradeOfNotification",
                          })}: ${job.equipment.details.gradeOfNotification}`}
                        </Typography>
                      )}
                      {!!job.equipment.details.notificationOption && (
                        <Typography>
                          {`${intl.formatMessage({
                            id: "job.equipment.details.notificationOption",
                          })}: ${job.equipment.details.notificationOption}`}
                        </Typography>
                      )}
                      {!!job.equipment.details.fireAlarmCategory && (
                        <Typography>
                          {`${intl.formatMessage({
                            id: "job.equipment.details.fireAlarmCategory",
                          })}: ${job.equipment.details.fireAlarmCategory}`}
                        </Typography>
                      )}
                      {!!job.equipment.details.fireAlarmType && (
                        <Typography>
                          {`${intl.formatMessage({
                            id: "job.equipment.details.fireAlarmType",
                          })}: ${job.equipment.details.fireAlarmType}`}
                        </Typography>
                      )}
                      {!!job.equipment.details.certificateNumber && (
                        <Typography>
                          {`${intl.formatMessage({
                            id: "job.equipment.details.certificateNumber",
                          })}: ${job.equipment.details.certificateNumber}`}
                        </Typography>
                      )}
                      {!!job.equipment.details.certificateDate && (
                        <Typography>
                          {`${intl.formatMessage({
                            id: "job.equipment.details.certificateDate",
                          })}: ${intlFormatDate(intl, job.equipment.details.certificateDate)}`}
                        </Typography>
                      )}
                      {!!job.equipment.details.isRemotelyMonitored && (
                        <Typography>
                          {`${intl.formatMessage({
                            id: "job.equipment.details.isRemotelyMonitored",
                          })}: ${intl.formatMessage({
                            id: "general.yes",
                          })}`}
                        </Typography>
                      )}
                      {!!job.equipment.details.authority && (
                        <Typography>
                          {`${intl.formatMessage({
                            id: "job.equipment.details.authority",
                          })}: ${job.equipment.details.authority}`}
                        </Typography>
                      )}
                      {!!job.equipment.details.verificationMethod && (
                        <Typography>
                          {`${intl.formatMessage({
                            id: "job.equipment.details.verificationMethod",
                          })}: ${job.equipment.details.verificationMethod}`}
                        </Typography>
                      )}
                      {!!job.equipment.details.alarmReceivingCentre && (
                        <Typography>
                          {`${intl.formatMessage({
                            id: "visit.equipment.details.alarmReceivingCentre",
                          })}: ${job.equipment.details.alarmReceivingCentre}`}
                        </Typography>
                      )}
                      {!!job.equipment.details.alarmReceivingCentreDigiNumber && (
                        <Typography>
                          {`${intl.formatMessage({
                            id: "job.equipment.details.alarmReceivingCentreDigiNumber",
                          })}: ${job.equipment.details.alarmReceivingCentreDigiNumber}`}
                        </Typography>
                      )}
                      {!!job.equipment.details.alarmReceivingCentreReferenceNumber && (
                        <Typography>
                          {`${intl.formatMessage({
                            id: "job.equipment.details.alarmReceivingCentreReferenceNumber",
                          })}: ${job.equipment.details.alarmReceivingCentreReferenceNumber}`}
                        </Typography>
                      )}
                      {!!job.equipment.details.stuNumber && (
                        <Typography>
                          {`${intl.formatMessage({
                            id: "job.equipment.details.stuNumber",
                          })}: ${job.equipment.details.stuNumber}`}
                        </Typography>
                      )}
                      {!!job.equipment.details.remoteIP && (
                        <Typography>
                          {`${intl.formatMessage({
                            id: "job.equipment.details.remoteIP",
                          })}: ${job.equipment.details.remoteIP}`}
                        </Typography>
                      )}
                      {!!job.equipment.details.customProperties?.length && (
                        <Grid container>
                          <Grid item>
                            {job.equipment.details.customProperties.map((cp, index) => (
                              <Typography key={`${cp?.code}-${index}`}>
                                {`${cp?.description}: ${formatCustomProperty(cp?.type, cp?.value)}`}
                              </Typography>
                            ))}
                          </Grid>
                          <Grid item xs={2}>
                            {!readonly && engineerSettings?.canEditCustomEquipmentProps && (
                              <EditIcon
                                sx={{ ml: 3 }}
                                onClick={() => dispatch(setMachinePropertiesOpen({ open: true }))}
                                data-testid="JobDetailTab-OpenEditEquipment"
                              />
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </>
                  }
                />
              )}
            </div>

            {!!job.equipment?.specifications.length && (
              <ListItem component="div" disableGutters className={classes.noPadding}>
                <ListItemIcon
                  onClick={() => dispatch(setEquipmentSpecificationsOpen({ open: true }))}
                  className={`min-width-38 ${classes.iconAutoMargin} ${classes.iconInfo}`}
                  sx={{
                    alignSelf: "flex-start",
                    mt: 1,
                    cursor: "pointer",
                  }}
                  data-testid="JobDetailTab-OpenEquipmentSpecifications"
                >
                  <Construction />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography fontWeight={700} color="secondary.dark">
                      {intl.formatMessage({ id: "visit.equipmentSpecifications" })}
                    </Typography>
                  }
                />
              </ListItem>
            )}

            {backTo === "planner" && (
              <ListItem component="div" disableGutters className={classes.noPadding}>
                <ListItemIcon className="min-width-38">
                  <BuildIcon />
                </ListItemIcon>
                <ListItemText
                  className={classes.bold}
                  data-testid="JobDetailsTab-AssignedTo"
                  primary={
                    !!job.engineerName
                      ? `${intl.formatMessage({ id: "visit.assignedTo" })} ${job.engineerName}`
                      : intl.formatMessage({ id: "general.unassigned" })
                  }
                />
              </ListItem>
            )}
          </>
        }
      />
      <CardContent style={{ paddingTop: 0 }}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            {(job.serviceLevel || job.chargeBand) && (
              <>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "1.2rem",
                  }}
                  color="black"
                >
                  <FormattedMessage id="visit.serviceTerms" />
                </Typography>
                <List dense disablePadding>
                  {job.serviceLevel && (
                    <ListItem disableGutters>
                      <ListItemText primary={job.serviceLevel} sx={{ marginLeft: "38px" }} />
                    </ListItem>
                  )}
                  {job.chargeBand && (
                    <ListItem disableGutters>
                      <ListItemText primary={job.chargeBand} sx={{ marginLeft: "38px" }} />
                    </ListItem>
                  )}
                </List>
              </>
            )}
          </Grid>
          <Grid item sx={{ pt: "4px !important" }}>
            <Contacts
              customerAddress={job.customer?.address!}
              contacts={contacts}
              preferredContact={job.preferredContact}
              readOnly={readonly}
            />
          </Grid>
          <Grid item>
            <StyledChips
              isOnStop={job.customer?.isOnStop}
              category={job.category}
              priority={job.priority}
              symptoms={job.symptoms}
            />
          </Grid>
          {job.symptomDescription && (
            <Grid item>
              <StyledTextFieldReadOnly
                label={intl.formatMessage({ id: "job.symptomDescription" })}
                multiline
                minRows={15}
                defaultValue={job?.symptomDescription}
                disabled
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
      <EquipmentSpecificationsDialog />
    </StyledCard>
  );
};
